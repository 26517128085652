table {
    font-family: 'MonaSans', Helvetica, arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  html[lang=jpn] table {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
  }
  
  html[lang=chn] table {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
  }
  
  /*table tr:nth-child(even){
    background-color: #f2f2f2;
  }*/
  
  table tbody tr:hover {
    background-color: rgb(252, 243, 209);
  }

  .users-table-wrapper__table {
    max-width: 100%;
    overflow-x: auto;
  }

  .users-table-header-textcolumn {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    width:20%;
    /*background-color: #f2f2f2;*/
    color: #212529;
  }

  .users-table-header-textcontent {
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: auto auto;
    grid-column-gap: 15px;
  }

  .users-table-header-textcontent span {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
  }

  .users-table-header-textcontent:before {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    transform: rotate(-45deg);
  }

  .users-table-header-textcontent:after {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    transform: rotate(135deg);
  }

  .users-table-header-textcontent:before,
  .users-table-header-textcontent:after {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border: 1px solid #333;
    border-width: 2px 2px 0 0;
    align-self: center;
  }

  .users-table-sorting-asc:before {
    opacity: 0.3;
  }

  .users-table-sorting-desc:after {
    opacity: 0.3;
    
  }

  .users-table-sorting-nosort:before {

  }

  .users-table-sorting-nosort:after {

  }

  .users-table-header-buttoncolumn {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    width:5%;
    text-align: center;
  }

  .users-table-textcell {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    width:20%;
  }

  .users-table-buttoncell {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    width:5%;
    text-align: center;
    /*background-color: white;*/
  }

 
  .paging-section {
      margin-top: 10px;
      text-align: center;
  }

  .btn-img-edit,
  .btn-img-delete {
    position: relative;
    width: 30px;
    height: 30px;
    text-indent: 30px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: initial;
    border: none;
    margin-right: 10px;
    padding-left: 0;
    cursor: pointer;
    outline: none !important;
  }

  .btn-img-edit {
    left: 10px;
    background-image: url('../../images/edit.svg');
    background-size: 18px;
  }

  .btn-img-delete {
    left: 10px;
    background-image: url('../../images/cancel.svg');
    background-size: 18px;
  }