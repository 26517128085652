.modal__overlay {
    animation: 0.2s linear 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.4);
}

html[lang=jpn] .modal h3 {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}
  
html[lang=chn] .modal h3  {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

html[lang=jpn] .modal label {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}
  
html[lang=chn] .modal label  {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

html[lang=jpn] .modal h5 {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}
  
html[lang=chn] .modal h5  {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

html[lang=jpn] .confirmation-popup-text-small {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}
  
html[lang=chn] .confirmation-popup-text-small  {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

.modal {
    position: relative;
    display: inline;
}

.modal__content-wrapper {
    position: fixed;
    top: 55px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1004;
    padding: 0;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal__content {
    position: relative;
    top: -10%;
    width: 850px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    overflow-x: hidden;
}

.modal__content.edit {
    max-height: 750px;
    top:-40px;
}

.modal__content.create {
    max-height: 650px;
}

.modal__content_resized {
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: 140px !important;
    border: 2px solid #7aba28;
    padding: 20px !important;
}

.modal__content h3 {
    margin-top: 0;
}

.modal__close-container {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
}

.modal__close {
    height: 60px;
    width: 60px;
    border-color: transparent;
    background: none;
    color: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    position: relative;
    -webkit-transition: all .3s;
    transition: all .3s;
    outline: none !important;
    top:5px;
}

.modal__close:after, 
.modal__close:before {
    top: 14px;
    left: 28px;
    background-color: #3d3d3d;
    content: "";
    display: block;
    background-color: #202020;
    width: 2px;
    height: 50%;
    position: absolute;
    top: 7px;
    left: 13px;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
}

.modal__close:before {
transform: rotate(-45deg);
}

.modal__close:after {
    transform: rotate(45deg);
}

.modal__footer {
    padding-top: 25px;
}

.text-right {
    text-align: right!important;
}

.assigned-apps-section {
    margin-top: 20px;
    max-height:200px; 
}

/* Apps section scrollbar styling*/
.assigned-apps-scrollable {
    max-height:170px;
    overflow-y:auto;
    scrollbar-width: thin;
    scrollbar-color: #adafb1;
    width:fit-content;
    padding-right: 40px;
  }
  
  /* Works on Chrome, Edge, and Safari */
  .assigned-apps-scrollable::-webkit-scrollbar {
    width: 8px;
  }
  
  .assigned-apps-scrollable::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .assigned-apps-scrollable::-webkit-scrollbar-thumb {
    background-color: #adafb1;
    border-radius: 20px;
  }
  /* /////////////////////////// */


.app-name-label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
    margin-left: 5px;
}

.app-list-checkbox {
    width: 15px;
    height: 15px;
    vertical-align: bottom;
    position: relative;
    top: -3px;
    margin: 0 5px 0 0;
    overflow: visible;
}

.confirmation-popup-text-small {
    font-weight: 400;
    font-size:18px;
    font-family: 'MonaSans', Helvetica, arial, sans-serif !important;
}

.no-applications-for-maintenance{
    font-weight: 400;
    font-size:16px;
    font-family: 'MonaSans', Helvetica, arial, sans-serif !important;
    padding-left: 10px;
}