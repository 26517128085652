/* English */
@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MonaSans';
  src: url('fonts/MonaSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}


/* Japanese */
@font-face {
  font-family: 'NotoSansJP';
  src: url('fonts/NotoJP/NotoSansJP-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('fonts/NotoJP/NotoSansJP-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('fonts/NotoJP/NotoSansJP-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('fonts/NotoJP/NotoSansJP-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Chinese */
@font-face {
  font-family: 'NotoSansSC';
  src: url('fonts/NotoCH/NotoSansSC-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansSC';
  src: url('fonts/NotoCH/NotoSansSC-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansSC';
  src: url('fonts/NotoCH/NotoSansSC-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSansSC';
  src: url('fonts/NotoCH/NotoSansSC-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'MonaSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[lang=jpn] body {
	font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}

html[lang=chn] body {
	font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

code {
  font-family: 'MonaSans', sans-serif;
}
