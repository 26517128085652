.icon-success {
    display:inline;
}

.operation-text {
    margin-left: 30px;
    display:inline-block;
    padding-top: 30px;
    font-weight: 400;
    font-size:18px;
    font-family: 'MonaSans', Helvetica, arial, sans-serif !important;
}

html[lang=jpn] .operation-text {
    font-family: 'NotoSansJP', source-han-sans-japanese, Helvetica, sans-serif !important;
}
  
html[lang=chn] .operation-text {
    font-family: 'NotoSansSC', Geneva, Microsoft YaHei New, Microsoft Yahei, å¾®è½¯é›…é»‘, å®‹ä½“, SimSun, STXihei, åŽæ–‡ç»†é»‘, sans-serif !important;
}

.operation-output-wrapper {
    text-align: center;
}
